.subheader > p {
  font-size: 1.125rem !important;

  line-height: 1.4 !important;

  font-weight: 500 !important;
}

h1 + .subheader {
  margin-top: -1.5rem;

  margin-bottom: 3rem;
}
