.json_viewer :global(.icon-container) {
  margin-left: -1rem !important;
  width: 1rem !important;
  transform: translateY(2px);
}

.json_viewer [style*='padding']:global(.object-key-val),
.json_viewer [style*='padding']:global(.variable-row) {
  padding-left: 1.25rem !important;
  padding-right: 0 !important;
}

.json_viewer :global(.variable-row) {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.json_viewer :global(.variable-row) > span:first-child {
  max-width: 100%;
  overflow-x: auto;
  text-wrap: nowrap;
}

.json_viewer
  :global(.variable-row)
  > span:first-child
  :global(.object-key)
  span {
  display: contents !important;
}

.json_viewer :global(.variable-row) :global(.variable-value) {
  display: block;
  overflow-x: auto;
  text-wrap: nowrap;
  min-width: 3rem;
  max-width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0.5rem !important;
}

.json_viewer :global(.variable-row) :global(.copy-to-clipboard-container) {
  position: absolute;
  left: 0;
  visibility: hidden;
}

.json_viewer
  :global(.variable-row):hover
  :global(.copy-to-clipboard-container) {
  visibility: visible;
}

/* colon between key : value */
:global(.variable-row) > span > :is(.json_viewer [style*='margin: 0px 5px']),
:global(.object-key) + :is(.json_viewer [style*='margin: 0px 5px']) {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.json_viewer :global(.copy-to-clipboard-container) {
  display: inline-block;
  width: 1rem;
  text-wrap: nowrap;
  transform: translate3d(0, 0.15rem, 0);
}

.json_viewer :global(.copy-to-clipboard-container) > span {
  display: inline-block !important;
  position: relative;
}

:is(.json_viewer :global(.copy-to-clipboard-container))
  :global(.copy-icon)
  + span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto !important;
  text-align: center;
  font-size: 1.4rem;
  line-height: 0.8;
  text-shadow:
    1px 1px 0 #fff,
    -1px -1px 0 #fff;
}
