.codeExampleNotification {
  border-radius: 0.25rem;
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(170 170 170 / var(--tw-text-opacity));
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  height: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
