:root .wrapper {
  position: relative;
  margin-right: -1rem;
  padding: 0;
  padding-top: 0.5rem;
}
:root .wrapper pre[class*='language-'],
:root .wrapper code[class*='language-'] {
  background: none;
  border: none;
  font-family: monospace;
  font-size: 1.02em;
  line-height: 1.3;
}
:root .wrapper pre[class*='language-'] {
  margin: 0;
  padding: 0;
  margin-bottom: -0.75rem;
  padding-bottom: 0.75rem;
}
:root .wrapper.wrapper__withCopyToClipboard pre[class*='language-'] {
  padding-right: 3.5rem;
}
:root .wrapper pre[class*='language-'] a {
  text-decoration-line: underline;
  text-decoration-style: dashed;
}
:root .wrapper pre[class*='language-'] a:hover {
  text-decoration-style: solid;
}

/*  Remove bg from some elements  */
:root .wrapper pre[class*='language-'] :global(.token.operator),
:root .wrapper pre[class*='language-'] :global(.token.entity),
:root .wrapper pre[class*='language-'] :global(.token.url),
:root .wrapper pre[class*='language-'] :global(.language-css .token.string),
:root .wrapper pre[class*='language-'] :global(.style .token.string) {
  background-color: transparent;
}
:root .wrapper pre.digits_1[class*='language-'] {
  padding-left: 2.3em;
}
:root .wrapper pre.digits_2[class*='language-'] {
  padding-left: 2.8em;
}
:root .wrapper pre.digits_3[class*='language-'] {
  padding-left: 3.4em;
}
:root .wrapper pre.digits_4[class*='language-'] {
  padding-left: 4em;
}
:root .wrapper pre.digits_5[class*='language-'] {
  padding-left: 4.6em;
}

/* Fix vertical scroll when line-numbers are enabled */
:root .wrapper pre[class*='language-'] :global(.line-numbers-rows) {
  bottom: 0;
  overflow: hidden;
}
:root .wrapper pre[class*='language-'] :global(.query-string-begin),
:root .wrapper pre[class*='language-'] :global(.query-string-equal-sign),
:root .wrapper pre[class*='language-'] :global(.query-string-separator) {
  color: #79562d;
}
:root .wrapper pre[class*='language-'] :global(.variable-example) {
  font-weight: 700;
}
:root .copyToClipboard {
  position: absolute;
  top: 0;
  right: 0.5rem;
  z-index: 40;
}
:root .copyToClipboard_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.375rem;

  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
}
:root .copyToClipboard_button span {
  display: inline-block;
  width: 0;
  overflow: hidden;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
}
:root .copyToClipboard_button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(221 221 221 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
:root .copyToClipboard_button:hover span {
  display: inline-block;
  width: auto;
  padding-left: 0.125rem;
  padding-right: 0.25rem;
}
:root .copyToClipboard_button svg {
  height: 1.25rem;
  width: 1.25rem;
}
