.gridBlock {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: 1.25rem;
  row-gap: 1rem;
}
@media (min-width: 640px) {
  .gridBlock {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .gridBlock2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .gridBlock3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .gridBlock4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.item {
  position: relative;
  font-weight: 400 !important;

  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.item:hover {
  text-decoration-line: none !important;
}

.item {
  display: block;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-radius: 0.25rem;
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
  height: 100%;
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));

  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #f8f8f8 var(--tw-gradient-to-position);
}

.item:has(a:hover):hover {
  --tw-border-opacity: 1;
  border-color: rgb(39 39 39 / var(--tw-border-opacity));
}

.item__highlight {
  --tw-border-opacity: 1;
  border-color: rgb(170 170 170 / var(--tw-border-opacity));
}

.item_title {
  margin: 0 !important;
  padding: 0 !important;
}

.item_titleLink {
  color: inherit !important;
}

.item_titleLink:hover {
  text-decoration-line: none !important;
}

.item_titleLink::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.item_description {
  margin: 0 !important;
  margin-top: 0.25rem !important;
  padding: 0 !important;
  line-height: 1.3;
}

.link__highlight .link_title,
.link__highlight .link_description {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
