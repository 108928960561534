.link {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity)) !important;

  --tw-text-opacity: 1 !important;
  color: rgb(105 105 105 / var(--tw-text-opacity)) !important;
  border-radius: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  font-size: 0.688rem;
  line-height: 1.4;
  position: relative;
  top: -0.075rem;
}

.link:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(13 121 194 / var(--tw-bg-opacity)) !important;

  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  text-decoration-line: none !important;
}
