.wrapper {
  overflow: hidden !important;
  border-radius: 0.25rem;

  --tw-bg-opacity: 1;
  background-color: rgb(168 54 5 / var(--tw-bg-opacity));
  font-size: 0.875rem;
  line-height: 1.4;

  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.header {
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.header h3 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 0.875rem !important;
  line-height: 1.4 !important;
  font-weight: 700 !important;
}
.header svg {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  transform: rotate(180deg) !important;
}
.content {
}
body .content > p {
  margin: 0;
  padding: 0;
}
body .content > p + p {
  margin-top: 0.5rem;
}
body .content a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  text-decoration-line: underline;
}
body .content a:hover {
  --tw-text-opacity: 1;
  color: rgb(221 221 221 / var(--tw-text-opacity));
  text-decoration-line: none;
}
.header__minimized {
  padding-bottom: 0;
}
.header__minimized + .content {
  display: none !important;
}
