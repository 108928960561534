.container {
  display: grid;
  gap: 2rem;
}

@media (min-width: 768px) {
  .container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.panel {
  border-radius: 0.25rem;
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

.panel h4 {
  margin: 0 !important;
  padding: 0 !important;
}

.panel_image {
  overflow: hidden;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

.panel_image img {
  aspect-ratio: 10 / 9;
  object-fit: cover;
}

.panel_content {
  padding: 1.25rem 1rem 1.25rem 1.75rem;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1.5rem;
  row-gap: 0.25rem;
  margin-top: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.4;
}

.link {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.25rem;
  white-space: nowrap;
}

.link svg {
  height: 1.25rem;
  width: 1.25rem;
  stroke-width: 2px;
}
