.loaderCircular {
  height: 2.5rem;
  width: 2.5rem;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.loaderCircular {
  animation: spin 1s linear infinite;

  --tw-text-opacity: 1;
  color: rgb(13 121 194 / var(--tw-text-opacity));
}
@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.loaderCircular circle {
  opacity: 0.8;
  stroke: currentcolor;
  stroke-width: 3.6px;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
}

@keyframes loader_progress {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
