.simpleLayout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
.simpleLayout input,
.simpleLayout button,
.simpleLayout select {
  margin: 0;

  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  border-width: 1px;
  border-radius: 0.25rem;
  border-color: var(--block-border);
}

.simpleLayout button {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}
.simpleLayout select {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.081rem;
  padding-bottom: 0.081rem;
}

.simpleForm {
  display: table;
}
.simpleForm > strong {
  display: table-caption;
  margin-bottom: 0.5rem;
  text-align: left;
  font-weight: 700;
}
.simpleForm > div {
  display: table-row;
}
.simpleForm > div > * {
  display: table-cell;
}
.simpleForm > div + div > * {
  margin-top: 0.5rem;
}
.simpleForm > div > label {
  padding-right: 1rem;
  vertical-align: baseline;
}
.simpleForm > div > input,
.simpleForm > div > select {
  width: 100%;
}
